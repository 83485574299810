export const dataFormatterNaApiWithTime = (data) => {
    let [newDate, newTime] = data.split('T')
    newTime = newTime.split('.')[0]
    let [year, month, date] = newDate.split('-')
    return date + '.' + month + '.' + year + ' ' + newTime
  }
  
  export function parseMillisecondsIntoReadableTime(milliseconds, locale) {
    //Get hours from milliseconds
    if (milliseconds < 0) 
     return locale.done 
    var hours = milliseconds / (1000 * 60 * 60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
  
    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;
  
    //Get remainder from minutes and convert to seconds
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
    if (absoluteHours != 0) {
      return `${h} ${locale.h} ${m} ${locale.m} ${s} ${locale.s}`
    }
    else if (absoluteMinutes != 0) {
      return `${m} ${locale.m} ${s} ${locale.s}`
    }
    else if (absoluteSeconds != 0) {
      return `${s} ${locale.s}`
    }
    else {
      return locale.done
    }
  }
  
  export function DateTimeToDateWithISO(date) {
    if(date.includes('T')){
      const [newDate] = date.split('T')
      return newDate
    } else {
      return date
    }
  }
  