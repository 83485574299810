// import router from '../../router/index'
import { dataFormatterNaApiWithTime } from '../../plugins/date-formatter'
var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};
import { jsPDF } from "jspdf";
export default {
    data() {
        return {
            isMobileDownloadStarted: { status: false, process: false },
            pdfViewModal: false,
            pdfUrl: "",
            blob: '',
            file: null,
            loadingDetailModal: false,
            uploadedImageCount: 0
        }
    },
    methods: {
        SET_IMAGE_QUALITY(imgToCompress, resizingFactor, quality) {
            // resizing the image
            console.log(imgToCompress.width);
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            const originalWidth = imgToCompress.width;
            const originalHeight = imgToCompress.height;

            const canvasWidth = originalWidth * resizingFactor;
            const canvasHeight = originalHeight * resizingFactor;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            context.drawImage(
                imgToCompress,
                0,
                0,
                originalWidth * resizingFactor,
                originalHeight * resizingFactor
            );
            // reducing the quality of the image
            return new Promise((resolve) => {
                canvas.toBlob(
                    (blob) => {
                        console.log(blob, canvas);
                        if (blob) {
                            // showing the compressed image
                            // resizedImage.src =
                            const newFile = new File([blob], "image.png", {
                                type: "image/png",
                                lastModified: Date.now(),
                            });
                            resolve({
                                blob,
                                result: URL.createObjectURL(blob),
                                file: newFile,
                            });
                        }
                    },
                    "image/jpeg",
                    quality
                );
            });
        },
        toDataURL(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        },
        SET_DATE(date) {
            return dataFormatterNaApiWithTime(date);
        },
        SPLIT_TEXT(text) {
            return text.match(/.{1,50}/g);
        },
        SPLIT_TEXT_COMMENT(text) {
            if (text.length > 100) {
                if (text.charAt(99) === ' ')
                    return text.match(/.{1,100}/g);
                let index = 100;
                while (text.charAt(index) !== ' ' && index < 120) {
                    index += 1;
                }
                var re = new RegExp(".{" + 1 + "," + (index + 1) + "}", "g");
                return text.match(re);
            }
            return text.match(/.{1,100}/g);
        },
        SPLIT_TEXT_QUESTION(text) {
            return text.match(/.{1,37}/g);
        },
        async SET_SHOW_PDF(file, email) {
            if (email)
                this.email = { status: true, file }
            else if (window.innerWidth <= 992) {
                const fd = new FormData();
                fd.append('ReportFile', file);
                const url = await this.$api.post(`CompanyReports/ExportFile`, fd) //await blobToBase64(file)
                console.log(url);
                window.location.href = this.$api.BASE_URL + url.data.substring(1, url.data.length)
                this.isMobileDownloadStarted = {
                    process: false, status: true, message: 'Download started. Please check your <span class= "text-primary" > downloaded folder of device.</span>'
                }
                setTimeout(() => {
                    this.isMobileDownloadStarted = { status: false, process: false }
                }, 2500);
            }
            //router.push(`/pdf-view?url=${doc.output('bloburl')}`)
            else
                window.open(URL.createObjectURL(this.blob), '_blank');
        },
        async SET_EXPORT_PDF(detail, email = false) {
            if (this.blob !== '') return this.SET_SHOW_PDF(this.file, email);
            this.uploadedImageCount = 0;
            const vm = this;
            //  this.isMobileDownloadStarted = { status: false, process: false }
            if (window.innerWidth <= 992 && !email)
                this.isMobileDownloadStarted = { status: true, message: "Pdf will be download.. Please wait.", process: true }

            console.log("here ?");
            const groups = groupBy(detail.answers, "groupName")
            const imgData = require('../../../public/images/logo.png')
            var doc = new jsPDF({ compress: true })
            // doc.addFont("", "", "normal")
            //doc.setFont("helvetica", "", 'italic')
            doc.setDrawColor(86, 196, 182) // draw red lines
            doc.line(10, 10, 70, 10) // horizontal line
            doc.line(140, 10, 200, 10) // horizontal line
            doc.line(10, 10, 10, 290) // vertical line
            doc.line(200, 10, 200, 290) // vertical line
            doc.line(10, 290, 200, 290) // horizontal line
            doc.setFontSize(24)
            doc.setTextColor(244, 158, 49);
            doc.setFont("helvetica", "", 'bold')
            doc.text(detail.report.name, 75, 13)
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(11)
            doc.setFont("helvetica", "", 'italic')
            doc.text("Soft Serve Support & Service Pty Ltd", 15, 32)
            doc.setFontSize(9)
            doc.text("8/11 Trade Street", 15, 38)
            doc.text("Ormiston QLD 4160", 15, 44)
            doc.text("+61 400 00 22 44", 15, 50)
            doc.setFontSize(9)
            doc.setFont("helvetica", "", 'bold')
            doc.setTextColor(86, 196, 182);
            doc.text(this.SET_DATE(detail.createdAt), 160, 20)
            doc.text("Technician Name:", 15, 60)
            doc.setFont("helvetica", "", 'italic')
            doc.setTextColor(0, 0, 0);
            doc.text('Saban Gulpelen', 55, 60)
            doc.setFont("helvetica", "", 'bold')
            doc.setTextColor(86, 196, 182);
            doc.text("Client:", 15, 70)
            doc.setFont("helvetica", "", 'italic')
            doc.setTextColor(0, 0, 0);
            doc.text(detail.company.name, 55, 70)
            doc.setFont("helvetica", "", 'bold')
            doc.setTextColor(86, 196, 182);
            doc.text("Address:", 15, 76)
            doc.setFont("helvetica", "", 'italic')
            doc.setTextColor(0, 0, 0);
            doc.text(detail.company.address === null ? 'not specified' : detail.company.address, 55, 76)
            doc.setFont("helvetica", "", 'bold')
            doc.setTextColor(86, 196, 182);
            doc.text("Arrival Time:", 15, 86)
            doc.setFont("helvetica", "", 'italic')
            doc.setTextColor(0, 0, 0);
            doc.text(this.SET_DATE(detail.arrivalTime), 55, 86)
            doc.setFont("helvetica", "", 'bold')
            doc.setTextColor(86, 196, 182);
            doc.text("Depart Time:", 15, 92)
            doc.setFont("helvetica", "", 'italic')
            doc.setTextColor(0, 0, 0);
            doc.text(this.SET_DATE(detail.departTime), 55, 92)
            doc.setFontSize(12)
            doc.setFont("helvetica", "", 'bold')
            doc.setTextColor(244, 158, 49);
            doc.text("Equipment Detail", 120, 75)
            doc.setFontSize(9)
            doc.setFont("helvetica", "", 'bold')
            doc.setTextColor(86, 196, 182);
            doc.text("Brand/Model:", 120, 86)
            doc.setFont("helvetica", "", 'italic')
            doc.setTextColor(0, 0, 0);
            doc.text(`${detail.product.brand.name} ${detail.product.model}`, 150, 86)
            doc.setFont("helvetica", "", 'bold')
            doc.setTextColor(86, 196, 182);
            doc.text("Serial Number:", 120, 92)
            doc.setFont("helvetica", "", 'italic')
            doc.setTextColor(0, 0, 0);
            doc.text(detail.product.companyProduct.serialNo, 150, 92)
            doc.addImage(imgData, 'PNG', 112, 25, 60, 45, undefined, 'FAST')
            let cursorLocation = 100
            let groupLocation = 100
            let questionLocation = 112
            for (const key in groups) {
                if (Object.hasOwnProperty.call(groups, key)) {
                    const element = groups[key];
                    if (cursorLocation > 200) {
                        doc.addPage()
                        doc.setDrawColor(86, 196, 182) // draw red lines
                        doc.line(10, 10, 70, 10) // horizontal line
                        doc.line(140, 10, 200, 10) // horizontal line
                        doc.line(10, 10, 10, 290) // vertical line
                        doc.line(200, 10, 200, 290) // vertical line
                        doc.line(10, 290, 200, 290) // horizontal line
                        doc.setFontSize(24)
                        doc.setTextColor(244, 158, 49);
                        doc.setFont("helvetica", "", 'bold')
                        doc.text(detail.report.name, 75, 13)
                        doc.setTextColor(0, 0, 0);
                        doc.setFontSize(11)
                        cursorLocation = 25
                        groupLocation = 25
                        questionLocation = 31
                    }
                    groupLocation = cursorLocation + 6
                    doc.line(15, cursorLocation, 190, cursorLocation)
                    cursorLocation += 12
                    doc.setTextColor(244, 158, 49);
                    doc.setFontSize(12)
                    doc.text(key, 15, groupLocation);
                    cursorLocation += 6;
                    let tmp = cursorLocation;
                    for (let i = 0; i < element.length; i++) {
                        const question = element[i]
                        if (i % 2 === 0 && tmp > cursorLocation) {
                            questionLocation = tmp
                            cursorLocation = tmp
                        }
                        else if (i % 2 === 0) {
                            questionLocation = cursorLocation
                        }
                        if (i % 2 === 1) cursorLocation = questionLocation
                        doc.setTextColor(86, 196, 182);
                        doc.setFontSize(12)
                        doc.setFont("helvetica", "", 'bold')
                        const QuestionHeader = this.SPLIT_TEXT_QUESTION(question.question);
                        doc.text(QuestionHeader, (i % 2 === 0 ? 15 : 110), questionLocation);
                        doc.setFontSize(9)
                        doc.setTextColor(0, 0, 0);
                        cursorLocation += (3 * QuestionHeader.length)
                        cursorLocation += 6;
                        for (let j = 0; j < question.answers.length; j = j + 2) {
                            const answer = question.answers[j].value
                            const nextAnswer = question.answers[j + 1] ? question.answers[j + 1].value : ""
                            doc.setFont("helvetica", "", 'italic')
                            const text = this.SPLIT_TEXT(`${answer}, ${nextAnswer}`);
                            if (cursorLocation > 280) {
                                doc.addPage()
                                doc.setDrawColor(86, 196, 182) // draw red lines
                                doc.line(10, 10, 70, 10) // horizontal line
                                doc.line(140, 10, 200, 10) // horizontal line
                                doc.line(10, 10, 10, 290) // vertical line
                                doc.line(200, 10, 200, 290) // vertical line
                                doc.line(10, 290, 200, 290) // horizontal line
                                doc.setFontSize(24)
                                doc.setTextColor(244, 158, 49);
                                doc.setFont("helvetica", "", 'bold')
                                doc.text(detail.report.name, 75, 13)
                                doc.setTextColor(0, 0, 0);
                                doc.setFont("helvetica", "", 'italic')
                                doc.setFontSize(9)
                                cursorLocation = 25
                                groupLocation = 25
                                questionLocation = 31
                            }
                            doc.text(text, (i % 2 === 0 ? 15 : 110), cursorLocation);
                            cursorLocation = cursorLocation + (6 * (text.length))
                            if (tmp < cursorLocation) tmp = cursorLocation
                        }
                    }
                }
            }


            doc.setTextColor(244, 158, 49);
            doc.setFontSize(12)
            cursorLocation += 20
            if (detail.comment !== '' && detail.comment !== null) {
                doc.text("Comment", 15, cursorLocation);
                doc.setFont("helvetica", "", 'italic')
                doc.setTextColor(0, 0, 0);
                cursorLocation += 6
                doc.setFontSize(10)
                const text = this.SPLIT_TEXT_COMMENT(detail.comment);
                doc.text(text, 15, cursorLocation)
                doc.setFontSize(12)
                cursorLocation += (6 * text.length);
            }
            doc.setFontSize(20)
            doc.setTextColor(86, 196, 182);
            doc.setFont("times", "", 'italic')
            doc.text("Thanks for your business!!", 70, cursorLocation + 20)
            console.log(detail);
            if (detail.images.length > 0) {
                doc.addPage();
                doc.setDrawColor(86, 196, 182) // draw red lines
                doc.line(10, 10, 70, 10) // horizontal line
                doc.line(140, 10, 200, 10) // horizontal line
                doc.line(10, 10, 10, 290) // vertical line
                doc.line(200, 10, 200, 290) // vertical line
                doc.line(10, 290, 200, 290) // horizontal line
                doc.setFontSize(24)
                doc.setTextColor(244, 158, 49);
                doc.setFont("helvetica", "", 'bold')
                doc.text(detail.report.name, 75, 13)
                doc.setTextColor(0, 0, 0);
                doc.setFont("helvetica", "", 'italic')
                doc.setFontSize(9)
                cursorLocation = 35
                groupLocation = 25
                questionLocation = 31
            }

            for (let i = 0; i < detail.images.length; i++) {
                const element = this.$api.BASE_URL + detail.images[i].path
                let img = new Image();
                img.src = element + '?r=' + Math.floor(Math.random() * 100000);
                img.crossOrigin = "anonymous"
                doc.addImage(img, 'PNG', 25, cursorLocation, 160, 105, '', 'FAST')
                console.log("here? here?");
                vm.uploadedImageCount = vm.uploadedImageCount + 1;
                console.log(vm.uploadedImageCount);
                if (i % 2 === 0)
                    cursorLocation += 125
                else if (detail.images.length - 1 !== i) {
                    doc.addPage();
                    doc.setDrawColor(86, 196, 182) // draw red lines
                    doc.line(10, 10, 70, 10) // horizontal line
                    doc.line(140, 10, 200, 10) // horizontal line
                    doc.line(10, 10, 10, 290) // vertical line
                    doc.line(200, 10, 200, 290) // vertical line
                    doc.line(10, 290, 200, 290) // horizontal line
                    doc.setFontSize(24)
                    doc.setTextColor(244, 158, 49);
                    doc.setFont("helvetica", "", 'bold')
                    doc.text(detail.report.name, 75, 13)
                    doc.setTextColor(0, 0, 0);
                    doc.setFont("helvetica", "", 'italic')
                    doc.setFontSize(9)
                    cursorLocation = 35
                    groupLocation = 25
                    questionLocation = 31
                }

            }
            const newName = `${detail.product.companyProduct.serialNo}-${this.SET_DATE(detail.createdAt)}.pdf`
            const file = new File([doc.output('blob')], newName, {
                type: doc.output('blob').type,
                lastModified: doc.output('blob').lastModified,
            });
            vm.file = file;
            vm.blob = doc.output('blob');
            this.SET_SHOW_PDF(file, email);

        },
        async GET_REPORT_DETAIL_THEN_EXPORT_PDF(id) {
            this.loadingDetailModal = true;
            const response = await this.$api.get(
                `CompanyReports/${id}`
            );
            if (response.result === "OK" && response.message === "OK") {
                this.loadingDetailModal = false;
                this.SET_EXPORT_PDF(response.data)
            } else this.$router.push("/");
            this.loadingDetailModal = false;
        },
    }
}